import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import SplashScreen from 'src/components/loading-screen/splash-screen';
import OnboardLayout from 'src/layouts/onboard/onboard-layout';
// layouts


// ----------------------------------------------------------------------

const RegisterPage = lazy(() => import('src/pages/onboarding/register'));

// ----------------------------------------------------------------------

export const onboardRoutes = [
  {
    path: 'onboarding',

    /*
    element: (
      <AuthClassicLayout title="Create DocRead Cockpit account">
         <Suspense fallback={<SplashScreen />}>
         <Outlet />
         </Suspense>
      </AuthClassicLayout>
    ),
    */

    /*
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    */

    element: (
      <OnboardLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </OnboardLayout>
    ),


    children: [
      { path: 'register', element: <RegisterPage /> }
    ],
  },
];
