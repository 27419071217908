
export const API_ENDPOINTS = {
    v1: {
        totalStats: 'assignments/api/v1/stats/totals',
        libraryStats: 'assignments/api/v1/stats/libraries',
        usersStats: 'assignments/api/v1/stats/users',
        documentStats: 'assignments/api/v1/stats/documents',
        audienceStats: 'assignments/api/v1/stats/audiences',
        groupStats: 'assignments/api/v1/stats/groups',
        documents: 'assignments/api/v1/documents',
        activityLog: 'core/api/v1/activityLog',
        audiences: 'assignments/api/v1/audiences',
        libraries: 'assignments/api/v1/libraries',
        librarySettings: 'core/api/v1/librarySettings',
        documentSettings: 'core/api/v1/documentSettings',
        overallStats: 'assignments/api/v1/stats',
        userSettings: 'core/api/v1/userSettings',
        registrations: 'core/api/v1/registrations',
        ownerSettings: 'assignments/api/v1/ownerSettings',
        admins: 'core/api/v1/admins',
        assignments: 'assignments/api/v1/userAssignments',
        receipts: 'assignments/api/v1/receipts',
        licenseStatus: 'assignments/api/v1/licenseStatus',
        account: 'assignments/api/v1/accounts',
        users: 'assignments/api/v1/users',
        groups: 'assignments/api/v1/groups'
    }
    /*
    v2: {
        assignments: 'assignments/api/v2/userAssignments',
    }
    */
}  