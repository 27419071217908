/* eslint-disable no-nested-ternary */
import { useMemo, useCallback, useState, useEffect } from 'react';
// config
import { AZURE_API, MS_GRAPH_URL } from 'src/config-global';
//
import { MsalProvider, useMsal, useMsalAuthentication } from '@azure/msal-react';
// import { InteractionStatus, InteractionType } from '@azure/msal-browser/dist/utils/BrowserConstants';
import { AuthError, AuthenticationResult, InteractionRequiredAuthError, InteractionStatus, InteractionType, PopupRequest, PublicClientApplication, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { AuthUserType } from 'src/auth/types';
import { getQueryParamValue } from 'src/utils/url-utils';
import { paths } from 'src/routes/paths';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AdminsService } from 'src/services/admins-service';
import { UserAdminInfo } from 'src/types/principals';
import { setCurrentUserEmail } from './msal-global-instance';
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
    msalInstance: PublicClientApplication;
};

type AuthProviderProps = {
    children: React.ReactNode;
};

const userEmail = getQueryParamValue('user');
const forceLogin = getQueryParamValue('login');

const loginRequest: RedirectRequest = {
    loginHint: userEmail || '',
    scopes: AZURE_API.graphAccessTokens,
    prompt: forceLogin ? 'login' : undefined,
    // scopes: AZURE_API.docReadAcessTokens,
};

const getUserFromGraph = async (token: string) => {

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-type', 'application/json');

    // Add additional fields here
    const userDataFields = [
        'displayName',
        'mail',
        'userPrinciplaName',
        'id'
    ];

    const organizationDataFields = [
        'id',
        'displayName',
        'preferredLanguage'
    ];

    const requests = {
        requests: [
            {
                id: 1,
                method: "GET",
                url: `/me?$select=${userDataFields.join()}`
            },
            {
                id: 2,
                method: "GET",
                url: `/organization?$select=${organizationDataFields.join()}`
            }
        ]
    };

    const result = await Promise.all([
        fetch(`${MS_GRAPH_URL}/$batch`, { method: 'POST', headers, body: JSON.stringify(requests) }).then((response) => response.json()),
        fetch(`${MS_GRAPH_URL}/me/photo/$value`, { method: 'GET', headers }).then((response) => response.blob())
    ]);

    const userData = result[0].responses.find((r: any) => r.id === '1').body;
    const organizationData = result[0].responses.find((r: any) => r.id === '2').body;
    const photo = result[1];

    return [
        userData,
        photo,
        organizationData
    ]
};

const getCurrentUser = async (accessToken: string): Promise<AuthUserType> => {

    try {
        const userFromGraphTask = getUserFromGraph(accessToken);
        const adminInfoTask = AdminsService.getIsAdminInfo();

        const result = await Promise.all([userFromGraphTask, adminInfoTask]);

        const [userData, photo, organizationData] = result[0];
        const adminInfo = result[1];

        const userRole = adminInfo && adminInfo.isSysAdmin
            ? "sysAdmin"
            : adminInfo && adminInfo.isAdmin ?
                "admin"
                : "";

        const user: AuthUserType = {
            displayName: userData.displayName,
            email: userData.mail,
            department: userData.department,
            manager: userData.manager,
            id: userData.id,
            adminInfo: adminInfo as UserAdminInfo,
            photoURL: URL.createObjectURL(photo),
            role: userRole,
            tenantId: organizationData?.value?.[0]?.id,
            // groups: userSettings?.securityGroups || [],        
            organization: {
                name: organizationData?.value?.[0]?.displayName,
                language: organizationData?.value?.[0]?.preferredLanguage,
                id: organizationData?.value?.[0]?.id
            }
        };

        // Initialize the current user email so that it can be used in axios requests
        setCurrentUserEmail(user.email);

        return user;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
}

const AuthProviderWrapper = ({ children }: AuthProviderProps) => {
    const { instance: msalInstance, inProgress } = useMsal();

    const [searchParams] = useSearchParams();

    const [user, setUser] = useState<AuthUserType | null>(null);
    const [loginError, setLoginError] = useState<string | null>(null);
    const [consentError, setConsentError] = useState<boolean>(false);
    const navigate = useNavigate();

    const { result: loginResult, login: msalLogin, error: msalError } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    const [loading, setLoading] = useState(true);

    const handleLoginWithRedirect = useCallback(async (loginProps?: PopupRequest | RedirectRequest | SsoSilentRequest) => {
        await msalLogin(InteractionType.Redirect, { ...loginRequest, ...loginProps });
    }, [msalLogin]);

    /// LOGOUT
    const handleLogout = useCallback(async () => {
        // sign out the user
        const account = msalInstance.getActiveAccount();
        await msalInstance.logoutRedirect({ account });
    }, [msalInstance]);


    const initialize = useCallback(async () => {
        try {

            if (loginResult) {
                const loginResponse = loginResult as AuthenticationResult;
                msalInstance.setActiveAccount(loginResponse.account);
                const currentUser = await getCurrentUser(loginResponse.accessToken);
                setUser(currentUser);
                setConsentError(false);
                setLoading(false);
            }

            if (msalError) {

                if (msalError instanceof InteractionRequiredAuthError) {
                    // We expect this error when the user is not authenticated so we can ignore it
                    setLoading(false);
                    return;
                }

                if (msalError instanceof AuthError) {

                    if (msalError.errorCode === 'consent_required') {

                        // Need to ask for 
                        // 1. consent to the scopes requested in the loginRequest                        
                        // 2. consent to the extraScopesToConsent requested in the loginRequest
                        try {
                            await msalLogin(InteractionType.Redirect, { ...loginRequest, prompt: 'consent' });
                        }
                        catch (e) {
                            console.error(e);
                            setLoginError(e.message);
                        }
                        finally {
                            setLoading(false);
                        }
                        return;
                    }

                    if (msalError.errorCode === 'invalid_resource' || msalError.errorCode === 'invalid_client') {
                        // The service principals have not been registerd in the tenant
                        // Redirect the user to the onboarding page
                        setLoading(false);
                        setConsentError(true);
                        // navigate(paths.onboarding.register);
                        // router.push(paths.onboarding.register);
                        return;
                    }
                }

                console.error(msalError);
                setLoginError(msalError.message);
                setLoading(false);
            }

        } catch (error) {
            console.error(error);
            setLoginError(error.message);
            setLoading(false);
            if (error.errorCode && error.errorCode === 'invalid_grant') {
                setConsentError(true);
            }
        }
    }, [msalInstance, loginResult, msalError, msalLogin]);

    useEffect(() => {
        if (consentError) {
            // navigate to the onboarding page if the user has not consented to the scopes
            navigate({
                pathname: paths.onboarding.register,
                search: searchParams.toString(),
            });
        }

    }, [consentError, navigate, searchParams]);


    useEffect(() => {
        initialize();
    }, [initialize]);

    // const isAdmin = user?.role === "Administrator" || user?.role === "System administrator";

    const memoizedValue = useMemo(() => {

        const isAdmin = user?.adminInfo?.isAdmin || user?.adminInfo?.isSysAdmin;
        const userName = user?.displayName;
        const authErrorError = isAdmin ? null : `You are not authorized to access this application. User: ${userName}`;
        const checkAuthenticated = loginResult && isAdmin ? 'authenticated' : 'unauthenticated';
        const status = loading ? 'loading' : checkAuthenticated;

        return {
            user: {
                ...user
            },
            method: 'azure',
            loading: status === 'loading',
            authenticated: status === 'authenticated',
            unauthenticated: status === 'unauthenticated',
            login: handleLoginWithRedirect,
            logout: handleLogout,
            loginError: loginError || authErrorError,
            inProgress: inProgress !== InteractionStatus.None,
        }
    }, [handleLoginWithRedirect, handleLogout, inProgress, loading, loginError, loginResult, user]);

    return (
        <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
    );
}

export const AuthProvider = ({ children, msalInstance }: Props) => (
    <MsalProvider instance={msalInstance} >
        <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </MsalProvider>
);







