import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
// import { AuthenticationResult, EventType } from '@azure/msal-browser';
import App from './App';
import { msalGlobalInstance } from './auth/context/azure/msal-global-instance';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// export const msalGlobalInstance = new PublicClientApplication(msalConfig);

msalGlobalInstance.initialize().then(() => {

  /*
  msalGlobalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalGlobalInstance.setActiveAccount(account);
    }
  });
  */

  root.render(
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App pca={msalGlobalInstance} />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
});