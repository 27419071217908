import { Theme, alpha } from '@mui/material/styles';
import { OrderByDirection } from './paginated-results';

export enum AssignmentCombinedStatus {
    Required = 0,
    Completed = 1,
    Overdue = 2,
    NotRequired = 3,
    Recommended = 4,
    Deleted = 5,
    Pending = 6
}

export enum AssignmentStatus {
    Assigned,
    Completed,
    CompletedBeforeOverdue,
    CompletedAfterOverdue,
    Overdue,
    Recommended
}


/*
export enum AssignmentCombinedStatusExtended {
    Required = 0,
    Completed = 1,
    Overdue = 2,
    NotRequired = 3,
    Recommended = 4,
    Deleted = 5,
    Pending = 6,
    CompletedBeforeOverdue = 7,
    CompletedAfterOverdue = 8
}
*/

export interface IconInfo {
    iconName: string;
    iconColor: string;
}

export enum AssignmentType {
    Required = 0,
    Recommended = 1
}

export interface AssignmentListItem {
    id: string;
    documentId: string;
    documentName: string;
    documentTitle: string;
    creationDate: string;
    completedDate?: string;
    dueDate: string;
    readingType: string;
    userEmail: string;
    status: string;
    audienceName: string;
    groupName: string;
    overdue: boolean;
    siteUrl: string;
    listId: string;
    documentUrl: string;
    userDisplayName: string;
    listUrl: string;
    combinedStatus: AssignmentCombinedStatus;
}

export interface AssignmentsQuery {
    userId?: string;
    documentId?: string;
    audienceId?: string;
    libraryId?: string;
    groupId?: string;
    status?: AssignmentStatus;
    assignmentType?: AssignmentType;
    overdue?: boolean;
    siteId?: string;
    documentContains?: string;
    audienceContains?: string;
    userContains?: string;
    groupContains?: string;
    libraryContains?: string;
    continuationToken?: string;
    pageSize?: number;
    orderByField?: string;
    orderByDirection?: OrderByDirection;
    completedAfterOverdue?: boolean;
}

export const STATUS_ICONS = {
    Required: "fluent-mdl2:away-status",
    Assigned: "fluent-mdl2:away-status",
    Completed: "fluent-mdl2:completed-solid",
    CompletedBeforeOverdue: "fluent-mdl2:completed-solid",
    CompletedAfterOverdue: "fluent-mdl2:completed-solid",
    NotRequired: "fluent-mdl2:status-error-full",
    Overdue: "fluent-mdl2:alert-solid",
    Recommended: "fluent-mdl2:away-status",
    Deleted: "fluent-mdl2:completed-solid",
    Pending: "fluent-mdl2:away-status"
}

export const ASSET_ICONS = {
    Assignment: "fluent:clipboard-task-24-regular",
    Receipt: "fluent:document-ribbon-24-regular",
    User: "fluent:person-24-regular",
    Document: "fluent:document-text-24-regular",
    Audience: "fluent:people-audience-24-regular",
    Library: "fluent:document-folder-24-regular",
    Email: "fluent:mail-24-regular",
    Phone: "fluent:call-24-regular",
    Log: "fluent:list-24-regular",
    Notifications: "fluent:mail-alert-24-regular",
    LibraryLink: "fluent:folder-link-24-regular",
    DocumentLink: "fluent:document-text-link-24-regular",
    DocumentVersion: "tabler:versions",
    Business: "ic:outline-business-center",
    Id: "fluent:tag-24-regular",
    GlobalAudience: "fluent:globe-24-regular",
    Description: "fluent:info-24-regular",
    Group: "fluent:people-team-24-regular",
}

export const ASSET_ICONS_FILLED = {
    Assignment: "fluent:clipboard-task-24-filled",
    Receipt: "fluent:document-ribbon-24-filled",
    User: "fluent:person-24-filled",
    Document: "fluent:document-text-24-filled",
    Audience: "fluent:people-audience-24-filled",
    Library: "fluent:document-folder-24-filled",
    Email: "fluent:mail-24-filled",
    Phone: "fluent:call-24-filled",
    Log: "fluent:list-24-filled",
    Notifications: "fluent:mail-alert-24-filled",
    LibraryLink: "fluent:folder-link-24-filled",
    DocumentLink: "fluent:document-text-link-24-filled",
    DocumentVersion: "tabler:versions-filled",
    Business: "ic:baseline-business-center",
    Id: "fluent:tag-24-filled",
    GlobalAudience: "fluent:globe-24-filled",
    Description: "fluent:info-24-filled",
    Group: "fluent:people-team-24-filled",
    Account: "ic:round-account-circle",
    Admins: "clarity:administrator-solid"
}

export const statusColors = (theme: Theme) => ({
    // Required: theme.palette.warning.main,
    [AssignmentStatus.Assigned]: theme.palette.warning.main,
    [AssignmentStatus.CompletedBeforeOverdue]: theme.palette.success.main,
    [AssignmentStatus.CompletedAfterOverdue]: theme.palette.warning.darker,
    [AssignmentStatus.Overdue]: theme.palette.error.dark,
    [AssignmentStatus.Completed]: theme.palette.success.main,
    [AssignmentStatus.Recommended]: theme.palette.info.dark
})

export const combinedStatusColors = (theme: Theme) => ({
    [AssignmentCombinedStatus.Required]: theme.palette.warning.main,
    [AssignmentCombinedStatus.Pending]: theme.palette.warning.main,
    [AssignmentCombinedStatus.Completed]: theme.palette.success.main,    
    [AssignmentCombinedStatus.Overdue]: theme.palette.error.dark,    
    [AssignmentCombinedStatus.Recommended]: theme.palette.info.dark,
    [AssignmentCombinedStatus.Deleted]: theme.palette.info.dark,
    [AssignmentCombinedStatus.NotRequired]: theme.palette.info.dark,
})


export const statusLabelColors = () => ({
    Assigned: 'warning',
    CompletedBeforeOverdue: 'success',
    CompletedAfterOverdue: 'success',
    Overdue: 'error',
    Completed: 'success',
    Recommended: 'info'
})



export const statusBackgroundColors = (theme: Theme) => ({
    Assigned: alpha(statusColors(theme)[AssignmentStatus.Assigned], 0.1),
    CompletedBeforeOverdue: alpha(statusColors(theme)[AssignmentStatus.CompletedAfterOverdue], 0.1),
    CompletedAfterOverdue: alpha(statusColors(theme)[AssignmentStatus.CompletedBeforeOverdue], 0.1),
    Overdue: alpha(statusColors(theme)[AssignmentStatus.Overdue], 0.1),
    Completed: alpha(statusColors(theme)[AssignmentStatus.Completed], 0.1),
    Recommended: alpha(statusColors(theme)[AssignmentStatus.Recommended], 0.1)
})


// export const getStatusIconNameExtended = (status: AssignmentCombinedStatusExtended): string => STATUS_ICONS[AssignmentCombinedStatusExtended[status] as keyof typeof STATUS_ICONS];
// export const getStatusColorExtended = (status: AssignmentCombinedStatusExtended, theme: Theme): string => statusColors(theme)[AssignmentCombinedStatusExtended[status] as keyof typeof statusColors];


export const assignmentCombinedStatusNames: Record<AssignmentCombinedStatus, string> = {
    [AssignmentCombinedStatus.Required]: 'Incomplete',
    [AssignmentCombinedStatus.Completed]: 'completed',
    [AssignmentCombinedStatus.Overdue]: 'Overdue',
    [AssignmentCombinedStatus.Recommended]: 'Recommended',
    [AssignmentCombinedStatus.NotRequired]: 'Not required',
    [AssignmentCombinedStatus.Deleted]: 'Deleted',
    [AssignmentCombinedStatus.Pending]: 'Pending',
};

export const assignmentStatusNames: Record<AssignmentStatus, string> = {
    [AssignmentStatus.Assigned]: 'Assigned',
    [AssignmentStatus.Completed]: 'Completed',
    [AssignmentStatus.Overdue]: 'Overdue',
    [AssignmentStatus.Recommended]: 'Recommended',
    [AssignmentStatus.CompletedBeforeOverdue]: 'Completed before overdue',
    [AssignmentStatus.CompletedAfterOverdue]: 'Completed after overdue',
};


/*
export const assignmentStatusNamesExtended: Map<AssignmentCombinedStatusExtended, string> = new Map([
    [AssignmentCombinedStatusExtended.Required, 'Incomplete'],
    [AssignmentCombinedStatusExtended.Completed, 'completed'],
    [AssignmentCombinedStatusExtended.Overdue, 'Overdue'],
    [AssignmentCombinedStatusExtended.Recommended, 'Recommended'],
    [AssignmentCombinedStatusExtended.Completed, 'Completed'],
    [AssignmentCombinedStatusExtended.CompletedBeforeOverdue, 'Completed before overdue'],
    [AssignmentCombinedStatusExtended.CompletedAfterOverdue, 'Completed after overdue'],
]);
*/

export const assignmentTypeNames: Record<AssignmentType, string> = {
    [AssignmentType.Required]: 'Required',
    [AssignmentType.Recommended]: 'Recommended',
};

export const getCombinedStatusName = (status: AssignmentCombinedStatus): string => assignmentCombinedStatusNames[status] ?? '';
export const getCombinedStatusIconName = (status: AssignmentCombinedStatus): string => STATUS_ICONS[AssignmentCombinedStatus[status] as keyof typeof STATUS_ICONS];
export const getCombinedStatusColor = (status: AssignmentCombinedStatus, theme: Theme): string => combinedStatusColors(theme)[[status] as keyof typeof statusColors];

export const getStatusName = (status: AssignmentStatus): string => assignmentStatusNames[status] ?? '';
export const getStatusIconName = (status: AssignmentStatus): string => STATUS_ICONS[AssignmentStatus[status] as keyof typeof STATUS_ICONS];
export const getStatusColor = (status: AssignmentStatus, theme: Theme): string => statusColors(theme)[status];

/*
export function getStatusNameExtended(status: AssignmentCombinedStatusExtended): string {
    return assignmentStatusNamesExtended.get(status) ?? '';
}
*/