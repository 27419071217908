import { PrincipalInfo, UserAdminInfo } from "src/types/principals";
import { API_ENDPOINTS } from "./api-endpoints";
import { ServiceBase } from "./service-base";

export class AdminsService {

    public static async getIsAdminInfo(): Promise<UserAdminInfo | null> {
        const adminInfo = await ServiceBase.get<UserAdminInfo>(`${API_ENDPOINTS.v1.admins}/isAdmin`);
        return adminInfo;
    }

    public static async getAdmins(): Promise<PrincipalInfo[]> {
        const admins = await ServiceBase.get<PrincipalInfo[]>(`${API_ENDPOINTS.v1.admins}`);

        if (admins == null) {
            return [];
        }

        return admins;
    }

    public static async createAdmin(admin: PrincipalInfo) {
        await ServiceBase.post(`${API_ENDPOINTS.v1.admins}`, admin);
    }

    public static async updateAdmin(admin: PrincipalInfo) {
        await ServiceBase.put(`${API_ENDPOINTS.v1.admins}`, admin);
    }

    public static async deleteAdmin(adminId: string, name?: string) {
        await ServiceBase.delete(`${API_ENDPOINTS.v1.admins}/${adminId}`, { params: { name } });
    }
}


