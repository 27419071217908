// ----------------------------------------------------------------------

import { AssignmentCombinedStatus } from "src/types/assignments";

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  ONBOARDING: '/onboarding',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    azure: {
      login: `${ROOTS.AUTH}/azure/login`,
      register: `${ROOTS.AUTH}/azure/register`,
    }
  },
  // ONBOARDING
  onboarding: {
    root: ROOTS.ONBOARDING,
    register: `${ROOTS.ONBOARDING}/register`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    globalStats: {
      dashboard: `${ROOTS.DASHBOARD}/global/dashboard`,
      audiences: `${ROOTS.DASHBOARD}/global/audiences`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/users`,
      profile: (id: string) => `${ROOTS.DASHBOARD}/users/${id}`,
    },
    group: {
      root: `${ROOTS.DASHBOARD}/groups`,
      profile: (id: string) => `${ROOTS.DASHBOARD}/groups/${id}`,
    },
    document: {
      root: `${ROOTS.DASHBOARD}/documents`,
      profile: (id: string) => `${ROOTS.DASHBOARD}/documents/${id}`,
    },
    audience: {
      root: `${ROOTS.DASHBOARD}/audiences`,
      profile: (id: string) => `${ROOTS.DASHBOARD}/audiences/${id}`,
    },
    library: {
      root: `${ROOTS.DASHBOARD}/libraries`,
      profile: (id: string) => `${ROOTS.DASHBOARD}/libraries/${id}`,
    },
    assignment: {
      root: (status?: AssignmentCombinedStatus) => status
        ? `${ROOTS.DASHBOARD}/assignments?status=${status}`
        : `${ROOTS.DASHBOARD}/assignments`,
    },
    receipts: {
      root: `${ROOTS.DASHBOARD}/receipts`
    },
    admin: {
      root: `${ROOTS.DASHBOARD}/admin`,
      activityLog: `${ROOTS.DASHBOARD}/admin/activitylog`,
      licensing: `${ROOTS.DASHBOARD}/admin/licensing`,
      notifications: `${ROOTS.DASHBOARD}/admin/notifications`,
      account: `${ROOTS.DASHBOARD}/admin/account`,
    },
  },
};
