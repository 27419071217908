import { PublicClientApplication } from '@azure/msal-browser';
import { AZURE_API } from 'src/config-global';
import { msalConfig } from './msal-config';

let currentUserEmail = '';

export const setCurrentUserEmail = (email: string) => {
    currentUserEmail = email;
};

export const getCurrentUserEmail = () => currentUserEmail;

export const acquireAccessToken = async (scopes?: string[]): Promise<string> => {
    const msalInstance = msalGlobalInstance;

    // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const activeAccount = msalInstance.getActiveAccount();

    if (!activeAccount) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
        throw new Error("User is not signed in");
    }

    const request = {
        scopes: scopes || AZURE_API.docReadAcessTokens,

        account: activeAccount
    };

    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken
};

export const msalGlobalInstance = new PublicClientApplication(msalConfig);
// await msalGlobalInstance.initialize();


/*
// create the mgt provider
const provider = new SimpleProvider(acquireAccessToken);
Providers.globalProvider = provider;

export const graphClient = provider.graph.client;
*/

/*
msalGlobalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const { account } = payload;
        msalGlobalInstance.setActiveAccount(account);
    }
});
*/