import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';



// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/home'));


// USERS
const UsersListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));

// Groups
const GroupsListPage = lazy(() => import('src/pages/dashboard/group/list'));
const GroupProfilePage = lazy(() => import('src/pages/dashboard/group/profile'));

// DOCUMENTS
const DocumentsListPage = lazy(() => import('src/pages/dashboard/documents/list'));
const DocumentProfilePage = lazy(() => import('src/pages/dashboard/documents/profile'));

// Audiences
const AudienceListPage = lazy(() => import('src/pages/dashboard/audience/list'));
const AudienceProfilePage = lazy(() => import('src/pages/dashboard/audience/profile'));

// Libraries
const LibraryListPage = lazy(() => import('src/pages/dashboard/library/list'));
const LibraryProfilePage = lazy(() => import('src/pages/dashboard/library/profile'));

// Assignments
const AssignmentListPage = lazy(() => import('src/pages/dashboard/assignments/list'));

// Receipts
const ReceiptsListPage = lazy(() => import('src/pages/dashboard/receipts/list'));

// Account
const AccountHome = lazy(() => import('src/pages/dashboard/account/account-home'));

// const UsersStatsListPage = lazy(() => import('src/pages/dashboard/users/list'));

// ADMINS
const AdminsListPage = lazy(() => import('src/pages/dashboard/admin/admins'));
const ActivityLogPage = lazy(() => import('src/pages/dashboard/admin/activity-log'));
const LicensingPage = lazy(() => import('src/pages/dashboard/admin/licensing'));
const NotificationsPage = lazy(() => import('src/pages/dashboard/admin/notifications'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },     
      {
        path: 'users',
        children: [
          { element: <UsersListPage />, index: true },
          { path: 'list', element: <UsersListPage /> },
          { path: ':id', element: <UserProfilePage /> },
        ],
      },
      {
        path: 'groups',
        children: [
          { element: <GroupsListPage />, index: true },
          { path: 'list', element: <GroupsListPage /> },
          { path: ':id', element: <GroupProfilePage /> },
        ],
      },
      {
        path: 'documents',
        children: [
          { element: <DocumentsListPage />, index: true },
          { path: 'list', element: <DocumentsListPage /> },
          { path: ':id', element: <DocumentProfilePage /> },
        ],
      },
      {
        path: 'audiences',
        children: [
          { element: <AudienceListPage />, index: true },
          { path: 'list', element: <AudienceListPage /> },
          { path: ':id', element: <AudienceProfilePage /> },
        ],
      },
      {
        path: 'libraries',
        children: [
          { element: <LibraryListPage />, index: true },
          { path: 'list', element: <LibraryListPage /> },
          { path: ':id', element: <LibraryProfilePage /> },
        ],
      },
      {
        path: 'assignments',
        children: [
          { element: <AssignmentListPage />, index: true },
          { path: 'list', element: <AssignmentListPage /> },
        ],
      },
      {
        path: 'receipts',
        children: [
          { element: <ReceiptsListPage />, index: true },
          { path: 'list', element: <ReceiptsListPage /> },
        ],
      },
      {
        path: 'admin',
        element: (<RoleBasedGuard roles={['sysAdmin']} ><Outlet /></RoleBasedGuard>),
        children: [
          { element: <AdminsListPage />, index: true },
          { path: 'admins', element: <AdminsListPage /> },
          { path: 'notifications', element: <NotificationsPage /> },
          { path: 'activitylog', element: <ActivityLogPage /> },
          { path: 'licensing', element: <LicensingPage /> },
          { path: 'account', element: <AccountHome /> },
        ],
      },
    ],

  },
];
