import axiosInstance from "src/utils/axios";
import { AxiosRequestConfig } from "axios";

export class ServiceBase {

    public static async get<T>(url: string, config?: AxiosRequestConfig<any>): Promise<T | null> {

        const response = await axiosInstance.get<T>(url, config);

        if (response.status === 404) {
            return null;
        }

        return response.data;

    }

    public static async put(url: string, data: any) {

        const response = await axiosInstance.put(url, data);

        return response.data;
    }

    public static async post(url: string, data: any) {

        const response = await axiosInstance.post(url, data);

        return response.data;
    }

    public static async post1<T>(url: string, data: any, params: any): Promise<T | null> {

        const response = await axiosInstance.post(url, data, params);

        return response.data;
    }



    public static async delete(url: string, config?: AxiosRequestConfig<any>) {

        const response = await axiosInstance.delete(url, config);

        return response.data;

    }
}