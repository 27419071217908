import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { AZURE_API } from "src/config-global";

export const msalConfig: Configuration = {
    auth: {
        clientId: AZURE_API.clientId || '',
        authority: AZURE_API.authority || '',
        redirectUri: AZURE_API.redirectUri || '',
        clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenge
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                console.log(message);
            },
            logLevel: LogLevel.Verbose
        }
    }
};
