// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const MS_GRAPH_URL = process.env.REACT_APP_MS_GRAPH_URL || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const AZURE_API = {
  clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
  authority: process.env.REACT_APP_AZURE_AUTHORITY,
  redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
  postLogoutRedirectUri: process.env.REACT_APP_AZURE_LOGOUT_REDIRECT_URI,
  // accessTokens: ['email', 'openid', 'profile', 'user.read'],
  // accessTokens: [process.env.REACT_APP_AZURE_ACCESS_TOKENS || ''],
  // accessTokens: ['openid', 'profile', 'User.Read', process.env.REACT_APP_DOCREAD_ACCESS_TOKEN || ''],
  // accessTokens: process.env.REACT_APP_AZURE_ACCESS_TOKENS?.split(',') || [],
  graphAccessTokens: process.env.REACT_APP_GRAPH_ACCESS_TOKENS?.split(',') || [],
  // onboardingAccessTokens: process.env.REACT_APP_ONBOARDING_ACCESS_TOKENS?.split(',') || [],
  docReadAcessTokens: [process.env.REACT_APP_DOCREAD_ACCESS_TOKEN || ''],
  adminRoleId: process.env.REACT_APP_AZURE_ADMIN_ROLE_ID || '',
};

export const SP_APP = {
  manifestUrl: process.env.REACT_APP_SP_APP_MANIFEST_URI || '',
  downloadUrl: process.env.REACT_APP_SP_APP_DOWNLOAD_URI || '',
}


export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'


export const AGREEMENTS = {
  userAgreement: process.env.REACT_APP_USER_AGREEMENT_URL || '',
  privacyPolicy: process.env.REACT_APP_PRIVACY_POLICY_URL || '',
}

export const EMAILS = {
  support: process.env.REACT_APP_SUPPORT_EMAIL || '',  
}