// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//
import { useEffect, useState } from 'react';
// import useLocales from './use-locales';
import { enUS } from 'date-fns/locale';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

// Get language from browser
const langCode = navigator.language || "en-US";


export default function LocalizationProvider({ children }: Props) {
  // const { currentLang } = useLocales();
  const [locale, setLocale] = useState(enUS);

  useEffect(() => {
    
    // dynamically update the language to the same as the browser
    const getLocale = async () => {
      const dateFnsLocale = await import(`date-fns/locale/${langCode}/index.js`);
      setLocale(dateFnsLocale.default);
    }
    
    getLocale();

  }, []);
  
  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </MuiLocalizationProvider>
  );
}
