import axios from 'axios';
import { acquireAccessToken } from 'src/auth/context/azure/msal-global-instance';
// config
import { HOST_API, AZURE_API } from 'src/config-global';
// import { acquireAccessToken } from 'src/graph/graph-get-token';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error) || 'Something went wrong')
);


axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const accessToken = await acquireAccessToken(AZURE_API.docReadAcessTokens);

      const bearer = `Bearer ${accessToken}`;
      config.headers.Authorization = bearer;
      config.headers['Content-Type'] = 'application/json';

      return config;
    }
    catch (error) {
      console.log(error);
      throw error;
    }

    /*
    const loginRequest = {
      // loginHint: getCurrentUserEmail(),
      account: msalGlobalInstance.getActiveAccount() ?? undefined,
      scopes: AZURE_API.docReadAcessTokens
      // scopes: AZURE_API.accessTokens      
    };

    try {
      const response = await msalGlobalInstance.acquireTokenSilent(loginRequest);

      const bearer = `Bearer ${response.accessToken}`;
      config.headers.Authorization = bearer;
      config.headers['Content-Type'] = 'application/json';

      return config;
    }
    catch (error) {
      console.log(error);
      throw error;
    }
    */

  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
