// @mui
import Box from '@mui/material/Box';
//

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function OnboardLayout({ children }: Props) {


    return (
        <Box sx={{
            py: 12,
            display: 'flex',
            minHeight: '100vh',
            textAlign: 'center',
            px: { xs: 2, md: 0 },
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'url(/logo/DocReadLogoBck.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'right',
        }}>

            <Box
                component="main"
                sx={{
                    py: 5,
                    px: 3,
                    // width: 1                 
                    margin: "auto"

                }}
            >
                {children}
            </Box>

        </Box>
    );
}
