import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';
import { ASSET_ICONS_FILLED } from 'src/types/assignments';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const iconify = (name: string) => (
  <Iconify icon={name} width={32} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  audience: icon('ic_audience'),
  // document: icon('ic_documents'),
  clock: icon('ic_clock'),
  group: icon('ic_group'),
  email: icon('ic_email'),
  logs: icon('ic_logs'),
  license: icon('ic_license'),
  assignments: iconify(ASSET_ICONS_FILLED.Assignment),
  receipts: iconify(ASSET_ICONS_FILLED.Receipt),
  users: iconify(ASSET_ICONS_FILLED.User),
  groups: iconify(ASSET_ICONS_FILLED.Group),
  audiences: iconify(ASSET_ICONS_FILLED.Audience),
  document: iconify(ASSET_ICONS_FILLED.Document),
  libraries: iconify(ASSET_ICONS_FILLED.Library),
  notifications: iconify(ASSET_ICONS_FILLED.Notifications),
  admin: iconify(ASSET_ICONS_FILLED.Admins),
  account: iconify(ASSET_ICONS_FILLED.Account),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // Global stats
      // ----------------------------------------------------------------------
      {
        subheader: 'Global stats',
        roles: ['admin', 'sysAdmin'],
        items: [
          { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.analytics, roles: ['admin', 'sysAdmin'] },
          { title: 'Users', path: paths.dashboard.user.root, icon: ICONS.users, roles: ['admin', 'sysAdmin'] },
          { title: 'Groups', path: paths.dashboard.group.root, icon: ICONS.groups, roles: ['admin', 'sysAdmin'] },
          { title: 'Documents', path: paths.dashboard.document.root, icon: ICONS.document, roles: ['admin', 'sysAdmin'] },
          { title: 'Audiences', path: paths.dashboard.audience.root, icon: ICONS.audiences, roles: ['admin', 'sysAdmin'] },
          { title: 'Libraries', path: paths.dashboard.library.root, icon: ICONS.libraries, roles: ['admin', 'sysAdmin'] },
          { title: 'Assignments', path: paths.dashboard.assignment.root(), icon: ICONS.assignments, roles: ['admin', 'sysAdmin'] },
          { title: 'Receipts', path: paths.dashboard.receipts.root, icon: ICONS.receipts, roles: ['admin', 'sysAdmin'] },
        ],
      },

      // Admin
      // ----------------------------------------------------------------------
      {
        subheader: 'Admin',
        roles: ['sysAdmin'],
        items: [
          { title: 'Administrators', path: paths.dashboard.admin.root, icon: ICONS.admin, roles: ['sysAdmin'] },
          { title: 'Activity log', path: paths.dashboard.admin.activityLog, icon: ICONS.logs, roles: ['sysAdmin'] },
          // { title: 'Notifications', path: paths.dashboard.admin.notifications, icon: ICONS.notifications, roles: ['sysAdmin'] },
          { title: 'Account', path: paths.dashboard.admin.account, icon: ICONS.account, roles: ['sysAdmin'] },
        ],
      },
    ],
    []
  );

  return data;
}
